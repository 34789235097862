'use strict';

const eventHandlers = require('./tracking/eventHandlers');
const eventsType = eventHandlers.eventsType;

const cache = {
    $selectorSwiperProduct: '.js-swiper-product-einstein',
    $selectorSwiperProductDescription: '.js-swiper-product-description-einstein'
};

/**
 * Validates and Return the cquotient namespace provided by the commerce cloud platform
 * @returns {Object} - einsteinUtils or null
 */
function getEinsteinUtils() {
    var einsteinUtils = window.CQuotient;
    if (einsteinUtils && (typeof einsteinUtils.getCQUserId === 'function') && (typeof einsteinUtils.getCQCookieId === 'function')) {
        return einsteinUtils;
    }
    return null;
}

/**
 * fills in the carousel with product tile html objects
 * @param {string} einsteinResponse string html for product tiles
 * @param {jQuery} $parentElement parent element where recommendations will show.
 */
function fillDomElement(einsteinResponse, $parentElement) {
    var recommender = $parentElement.data('recommender');
    var recommendedProducts = einsteinResponse[recommender].recs;
    if (recommendedProducts && recommendedProducts.length > 0) {
        var template = $parentElement.data('template');
        var components = [];
        components = recommendedProducts.map(function (recommendedProduct) {
            var tiledefinition = {};
            tiledefinition.template = template;
            tiledefinition.model = {
                type: 'product',
                id: recommendedProduct.id
            };
            return tiledefinition;
        });

        var url = new URL($parentElement.data('product-load-url'));
        url.searchParams.append('components', JSON.stringify(components));
        url.searchParams.append('limit', $parentElement.data('limit'));
        url.searchParams.append('recommender', recommender);

        $.ajax({
            url: url.href,
            type: 'get',
            dataType: 'html',
            success: function (html) {
               $parentElement.find('.swiper-wrapper').html(html);
               $parentElement.find('.swiper-wrapper')[0].style.height = 'auto';
            },
            error: function () {}
        });
    } else {
        $parentElement.prev('.js-no-recommendation').removeClass('d-none');
        $parentElement.addClass('d-none');
    }
}

 /**
 * Processes a recommendation tile, with an already initialized category specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @param {Object} einsteinUtils cquotient object
 * @param {Array} anchorsArray array of objects representing anchors
 */
function processRecommendationsTile($parentElement, einsteinUtils, anchorsArray) {
    var recommender = $parentElement.data('recommender');

    var params = {
        userId: einsteinUtils.getCQUserId(),
        cookieId: einsteinUtils.getCQCookieId(),
        ccver: '1.01'
    };

    if (anchorsArray) {
        params.anchors = anchorsArray;
    }

    /**
    * Processes a recommendation responses
    * @param {Object} einsteinResponse cquotient object
    */
    function recommendationsReceived(einsteinResponse) {
        fillDomElement(einsteinResponse, $parentElement);
    }

    if (einsteinUtils.getRecs) {
        einsteinUtils.getRecs(einsteinUtils.clientId, recommender, params, recommendationsReceived);
    } else {
        einsteinUtils.widgets = einsteinUtils.widgets || []; // eslint-disable-line no-param-reassign
        einsteinUtils.widgets.push({
            recommenderName: recommender,
            parameters: params,
            callback: recommendationsReceived
        });
    }
}

/**
 * Processes a recommendation tile, with an already initialized product specific anchors array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createProductAnchor($parentElement) {
    return [{
        id: $parentElement.data('primaryProductId'),
        sku: $parentElement.data('secondaryProductId'),
        type: $parentElement.data('alternativeGroupType'),
        alt_id: $parentElement.data('alternativeGroupId')
    }];
}

/**
 * Rerieves data attributes from parent element and converts to gretel compatible recommenders array
 * @param {jQuery} $parentElement parent element where recommendations will show.
 * @returns {Array} - containing an anchor object
 */
function createCategoryAnchor($parentElement) {
    return [{ id: $parentElement.data('categoryId') }];
}

/**
 * Gets all placeholder elements, which hold einstein recommendations queries the details from the
 * einstein engine and feeds them back to the dom element
 */
function loadRecommendations() {
    return new Promise((resolve, reject) => {
        var einsteinUtils = getEinsteinUtils();
        if (einsteinUtils) {
            var $recommendationTiles = $('.einstein-carousel');
            var pendingRequests = $recommendationTiles.length;

            $recommendationTiles.each(function () {
                var $parentElement = $(this);

                function callback() {
                    pendingRequests--;
                    if (pendingRequests === 0) {
                        resolve();
                    }
                }

                if ($(this).closest('.experience-einstein-einsteinCarouselProduct').length) {
                    processRecommendationsTile($parentElement, einsteinUtils, createProductAnchor($parentElement));
                    callback();
                } else if ($(this).closest('.experience-einstein-einsteinCarouselCategory').length) {
                    processRecommendationsTile($parentElement, einsteinUtils, createCategoryAnchor($parentElement));
                    callback();
                } else {
                    processRecommendationsTile($parentElement, einsteinUtils);
                    callback();
                }
            });
        } else {
            reject('Einstein utils not available');
        }
    });
}

$(document).ready(function () {
    loadRecommendations().then(function () {
        initSwipersProductEinstein().then(function () {
            var carouselContainer = $(cache.$selectorSwiperProduct);
            var carouselDescriptionContainer = $(cache.$selectorSwiperProductDescription);

            var infoItems = carouselContainer.find('.js-info-tracking');

            if (infoItems.length > 0) {
                eventHandlers.sendEvent(infoItems, eventsType.viewItemList);
            }

            var infoItemsDescription = carouselDescriptionContainer.find('.js-info-tracking');

            if (infoItemsDescription.length > 0) {
                eventHandlers.sendEvent(infoItemsDescription, eventsType.viewItemList);
            }
        });
    }).catch(function () {});
});

function initSwipersProductEinstein() {
    return new Promise((resolve) => {
        const swiperProductEinstein = new Swiper(cache.$selectorSwiperProduct, {
            modules: [Autoplay, Navigation, Pagination, Scrollbar],
            slidesPerView: 4,
            spaceBetween: 28,
            autoHeight: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction'
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                },
                576: {
                    slidesPerView: 2,
                },
                992: {
                    slidesPerView: 3,
                },
                1200: {
                    slidesPerView: 4,
                },
            },
        });

        const swiperProductDescriptionEinstein = new Swiper(cache.$selectorSwiperProductDescription, {
            modules: [Autoplay, Navigation, Pagination, Scrollbar],
            slidesPerView: 3,
            spaceBetween: 28,
            autoHeight: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction'
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.5,
                },
                768: {
                    slidesPerView: 2,
                },
                1200: {
                    slidesPerView: 2,
                },
                1400: {
                    slidesPerView: 3,
                },
            },
        });

        resolve();
    });
}
